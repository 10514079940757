.body {
  display: flex;
  max-width: 100%;
}


.header-cont {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #E5E8EB;
  padding: 1vh 1vw;
}


.header-container-row-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}


.header-container-row-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}


.displey-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}


.bold-font {
  font-weight: bold;
}


.font-18 {
  font-size: 18px;
}


.font-14 {
  font-size: 14px;
}

.font-16 {
	font-size: 16px;
}


.font-32 {
  font-size: 32px;
  font-weight: bold;
}


.color-title {
  color: #637087
}


.button-square {
  padding: 10px;
  border-radius: 12px;
  background-color: #F0F2F5;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.button-ser {
	padding: 5px 10px;
	border-radius: 12px;
	background-color: #F0F2F5;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}


.side-menu-cont {
  background-color: #ffffff;
  width: max-content;
  border-right: 1px solid #E5E8EB;
  min-height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.column-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.space{
	height: 5vh;
}


.elem-list {
  padding: 5px 10px;
  cursor: pointer;
}


.header-list-conainer {
  gap: 15px;
  font-size: 14px;
  padding: 12px 0;
}


.elem-list:hover {
  background-color: #F0F2F5;
}


.main-block {
  max-width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: #3a3f47; */
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;


}


.center-block {
  display: flex;
  /* background-color: #637087; */
  max-width: 1280px;
	min-width: 50vw;
  min-height: 100vh;
  justify-content: center;


}

.selectedInList{
  background-color: #E8EDF2;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 10px;
  border-radius: 10px;
  width: 100%;
  max-width: max-content;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  margin: 2px 2px;
  white-space: nowrap;
  overflow: hidden;
}

.flex-wrap{
  flex-wrap: wrap;
}


.list-content {
  max-width: 765px;
  width: 100%;
  padding: 15px;
}


.element-list-input {
  font-size: 16px;
  gap: 8px;
  padding: 12px 0;
  width: 100%;
  outline-width: 0;
}


.element-input-style {
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  background-color: #F0F2F5;
  outline-width: 0;
  appearance: none;
}


.mini-element-input-style {
  padding: 5px 16px;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  outline-width: 0;
  outline: none;
  background-color: #F0F2F5;
  width: min-content;
  appearance: none;
}


.element-input-style:focus {
  outline: none;
}


.title {
  padding: 20px 0;
}


.podtitle {
  padding: 10px 0;
}


.radio-button {
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #F0F2F5;
}


.element-list-select {
  width: 100%;
  /* block w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline */
}


.file-block {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.background-color-E8EDF2 {
  background-color: #E8EDF2;
}


.border-radius {
  border-radius: 10px;
}


.gap {
  gap: 10px;
}


.container-free-students {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  min-width: max-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container-model-choose {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: clip;
  gap: 5px;
  display: flex;
  padding: 10px;
  border: 1px dashed #637087;
}

.container-free-students-list {
  max-width: 30vw;
  max-height: 50vh;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: clip;
  gap: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  border: 1px dashed #637087;
}


.element-st {
  background-color: #E8EDF2;
  height: fit-content;
  padding: 2px 8px;
  border-radius: 10px;
  max-width: 10vw;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
.ten ::before{
  content: '';
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 2;
}


.button-classic {
  background-color: #1A5CE5;
  color: white;
  padding: 5px 16px;
  border-radius: 10px;
}
.button-classic-dop{
  width: max-content;
  margin: 10px 0;
}


.block-student {
  width: max-content
}


.block-student span {
  width: 100%;
}


.block-student span:hover {
  background-color: #D9DEE3;
}
.table-depence{
  width: inherit;
}


.table {
  max-width: 95vw;
}


table tr {
  height: 6vh;
}


table th,
table td {
  padding: 5px 10px;
  text-wrap: wrap;
  font-size: 16px;
  text-overflow: ellipsis;
  border-radius: 3px;
  max-width: 250px;
  overflow: hidden;
}


tr td:nth-child(1+1n) {
  color: #637087;
}


.filter {
  padding: 12px 16px;
  font-size: 14px;
  max-width: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 20px;


}


.common-line-filter {
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  height: max-content;
  transition-duration: 3s;
}




.black-line-filter {
  width: 100%;
  position: absolute;
  background-color: black;
  height: 1px;
  margin: auto auto;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;


}


.line-filter {
  width: 100%;
  background-color: #1A5CE5;
  height: 5px;
  border-radius: 10px;
  transition: background-color sease-in-out 1s;
}


.filter button {
  padding: 5px 16px;
}


.margin-right {
  margin-left: auto;
}


.search-full-screen {
  padding: 12px 0;
}


.search-full-screen input {
  padding: 12px 16px;
  background-color: #F0F2F5;
  border-radius: 10px;
  outline: none;
}


.left-column-command {
  max-width: 35vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.column-command-create {
  gap: 5px;
  padding: 10px;
}


.margin-top {
  margin-top: 20px;
}


.w-100 {
  width: 100%;
}


.margin {
  margin: 12px 0;
}


.img-command {
  /* background-image: url(command.svg); */
  background-color: #E8EDF2;
  border-radius: 10px;
  margin:auto 0;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-size: contain ;
}


.border-drag-and-drop {
  outline: 3px solid #E8EDF2;
  border-radius: 10px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
  
table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.manageMenu{
  grid-template-columns:1fr 1fr;
  grid-auto-rows: max-content;
  gap: 10px;
}
.manageMenu div{
  height: max-content;
}

.elementWithButtonKrest{
  background-color: #E8EDF2;
  padding: 4px 8px;
  border-radius: 10px;
  max-width: max-content;
  overflow: hidden;
  text-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin: 5px;
}
.gap_25{
  gap:25px;
}
.align-center{
	align-items: center;
}
table{
  border-collapse: unset !important;
}

